<template>
  <div>
    <div class="wrapper" id="appointmentCard">
      <!--------------------- loading --------------------->
      <Loading v-if="isLoading" class="section-dark section-loading"></Loading>

      <!-- <section class="section section-duke section-dark jpadding jpadding-25 section-unfixed">
        <div class="appointment-grid">
          <div class="jtitle text-center mb-5">
            <h3><i class="fad fa-check text-gold fa-3x"></i></h3>
            <h3>Appointment booked.</h3>
          </div>
          <div>
            <p>
              Thank you for booking your service appointment. The schedule will have to be confirmed manually
              by our Team.
            </p>
            <p>
              We will send you a confirmation per email shortly. You can also consult your appoitments in the
              Owners Club to stay up to date.
            </p>
          </div>
        </div>

        <div class="mt-5 text-center">
          <router-link :to="{ name: 'AppointmentsList' }" class="jbtn-watch-link text-gold">
            <i class="fal fa-clock"></i> MY APPOINTMENTS
          </router-link>
        </div>
      </section> -->

      <!--------------------- section --------------------->
      <section v-if="!isLoading" key="content" class="section-duke jpadding jpadding-20">
        <!-- <div class="jtitle jtitle-80 text-center">
          <h3>My Appointment</h3>
        </div>-->

        <!--------------------- card --------------------->

        <div v-if="!isLoading" key="list" class="">
          <div class="jcard jcard-main jcard-dark jcard-nohover">
            <!-- title -->
            <div class="text-center">
              <h3 class="mb-1">APPOINTMENT INFO</h3>
              <p>
                <small
                  ><span class="badge badge-pill badge-light">{{ appointmentId }}</span></small
                >
              </p>
            </div>

            <!-- ----- step 1: unconfirmed --------------------------------- -->
            <div
              v-if="appointment.status == $C.STATUS.APPOINTMENT_STATUS.UNCONFIRMED"
              key="step1"
              id="step1"
              class="appointment-step"
            >
              <div class="appointment-step-content text-center">
                <h3><i class="fad fa-calendar-check fa-2x"></i></h3>
                <h5>Request sent</h5>
                <p>Waiting for DUKE to confirm the date.<br /><br /></p>
                <p>
                  Requested Date:<br />
                  <b>{{ $moment($C.getDateFromTimestamp(appointment.date)).format("MMM YYYY") }}</b>
                </p>
              </div>
            </div>
            <!-- ----- step 2: confirmed --------------------------------- -->
            <div
              v-if="appointment.status == $C.STATUS.APPOINTMENT_STATUS.CONFIRMED"
              key="step2"
              id="step2"
              class="appointment-step"
            >
              <div class="appointment-step-content text-center">
                <h3><i class="fad fa-check fa-2x"></i></h3>
                <h5>Request Confirmed</h5>
                <p>
                  Waiting for watch to arrive in shop.
                </p>
              </div>
            </div>
            <!-- ----- step -1: declined --------------------------------- -->
            <div
              v-if="appointment.status == $C.STATUS.APPOINTMENT_STATUS.DECLINED"
              key="step9"
              id="step9"
              class="appointment-step"
            >
              <div class="appointment-step-content text-center">
                <h3><i class="fal fa-times-circle fa-2x"></i></h3>
                <h5>Request Declined</h5>
                <p>
                  <b>Reason:</b><br>
                  <span v-if="appointment.declineReason">{{ appointment.declineReason }}</span>
                  <span v-else>No reason specified.</span>
                  
                </p>
              </div>
            </div>

            <!-- ----- step 3: in progress --------------------------------- -->
            <div
              v-if="appointment.status == $C.STATUS.APPOINTMENT_STATUS.WATCH_RECEIVED"
              key="step3"
              id="step3"
              class="appointment-step"
            >
              <div class="appointment-step-content text-center">
                <h3><i class="fal fa-box-alt fa-2x"></i></h3>
                <h5>Watch has <br />arrived in shop</h5>
                <p>We will start working on it soon.</p>
              </div>

            </div>
            <!-- ----- step 4: in progress --------------------------------- -->
            <div
              v-if="appointment.status == $C.STATUS.APPOINTMENT_STATUS.IN_PROGRESS"
              key="step4"
              id="step4"
              class="appointment-step"
            >
              <div class="appointment-step-content text-center">
                <h3><i class="fal fa-cog fa-2x"></i></h3>
                <h5>Work in progress</h5>
                <p>We are currently working on your watch.</p>
              </div>

              <!-- estimated finish date -->
              <div v-if="appointment.estimatedFinishDate" class="jcard-border mb-3">
                <p class="p1-platform">
                  <small><i class="fal fa-cog"></i> Estimated finish date</small><br />{{
                    $moment($C.getDateFromTimestamp(appointment.estimatedFinishDate)).format("DD/MM/YYYY")
                  }}
                </p>
              </div>
            </div>
            <!-- ----- step 5: delivery --------------------------------- -->
            <div
              v-if="appointment.status == $C.STATUS.APPOINTMENT_STATUS.WORK_COMPLETED"
              key="step5"
              id="step5"
              class="appointment-step"
            >
              <div class="appointment-step-content text-center">
                <h3><i class="fal fa-tools fa-2x"></i></h3>
                <h5>Work Completed</h5>
                <p>Maintenance is done. Your watch will be disptached soon.</p>
              </div>
            </div>
            <!-- ----- step 6: in delivery --------------------------------- -->
            <div
              v-if="appointment.status == $C.STATUS.APPOINTMENT_STATUS.IN_DELIVERY"
              key="step6"
              id="step6"
              class="appointment-step"
            >
              <div class="appointment-step-content text-center">
                <h3><i class="fal fa-truck fa-2x"></i></h3>
                <h5>In Delivery</h5>
                <p>Your watch will arrive soon.</p>
              </div>

              <div class="bottom-grid mb-3">
                <!-- dispatch date -->
                <div v-if="appointment.dispatchDate" class="jcard-border">
                  <p class="p1-platform">
                    <small><i class="fal fa-truck"></i> Dispatch date</small><br />{{
                      $moment($C.getDateFromTimestamp(appointment.dispatchDate)).format("DD/MM/YYYY")
                    }}
                  </p>
                </div>
                <!-- delivery date -->
                <div v-if="appointment.deliveryDate" class="jcard-border">
                  <p class="p1-platform">
                    <small><i class="fal fa-truck"></i> Estimated delivery date</small><br />{{
                      $moment($C.getDateFromTimestamp(appointment.deliveryDate)).format("DD/MM/YYYY")
                    }}
                  </p>
                </div>
                <!-- delivery carrier -->
                <div v-if="appointment.deliveryCarrier" class="jcard-border">
                  <p class="p1-platform">
                    <small><i class="fal fa-truck"></i> Carrier</small><br />{{appointment.deliveryCarrier}}
                  </p>
                </div>
                <!-- delivery tracking id -->
                <div v-if="appointment.deliveryTrackingId" class="jcard-border">
                  <p class="p1-platform">
                    <small><i class="fal fa-box-alt"></i> Tracking Id</small><br />{{appointment.deliveryTrackingId}}
                  </p>
                </div>
              </div>
            </div>
            <!-- ----- step 7: completed --------------------------------- -->
            <div
              v-if="appointment.status == $C.STATUS.APPOINTMENT_STATUS.COMPLETED"
              key="step7"
              id="step7"
              class="appointment-step"
            >
              <div class="appointment-step-content text-center">
                <h3><i class="fal fa-check-circle fa-2x"></i></h3>
                <h5>Request Completed</h5>
                <p>
                  All done!
                </p>
              </div>

              <!-- service -->
              <div class="jcard-border mb-3">
                <p class="p1-platform">
                  <small
                    ><i class="fal fa-info-circle"></i> This appointment has been completed.<br />The service
                    has been archived in your watch's Service History.</small
                  >
                </p>
              </div>
            </div>

            <!-- service -->
            <div class="jcard-border">
              <p class="p1-platform">
                <small><i class="fal fa-cog"></i> SERVICE</small><br />{{ service.name }}
              </p>
            </div>

            <!-- details -->
            <div class="bottom-grid">
              <div class="jcard-border">
                <p class="p1-platform">
                  <small><i class="fal fa-calendar"></i> DATE</small><br />{{
                    $moment($C.getDateFromTimestamp(appointment.date)).format("MM/YYYY")
                  }}
                </p>
              </div>
              <div class="jcard-border">
                <p class="p1-platform">
                  <small><i class="fal fa-watch"></i> WATCH MODEL</small><br />{{ watchModel.name }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!--------------------- empty --------------------->
        <div v-else key="empty" class="section-empty">
          <h5 class="mb-3"><i class="fad fa-empty-set fa-2x"></i><br /></h5>
          <p>
            Error loading appointment data.
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import C from "@/const";
import toast from "@/assets/js/toast";
import Loading from "@/components/general/Loading";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;
const serverTimestamp = firebase.serverTimestamp;

export default {
  name: "MyAppointments",
  components: {
    Loading: Loading
  },
  data() {
    return {
      isLoading: true,

      //form error alert
      showInputError: false,
      validationMsg: "",

      //form button
      isSaving: false,
      isDone: false,

      appointment: null,
      service: null,
      watch: null,
      watchModel: null,

      appointmentId: "",
      editData: {
        name: "",
        date: new Date()
      }
    };
  },
  methods: {
    loadingDone: function(error) {
      this.$root.$emit("loadingDone", error);
      if (!error) this.isLoading = false;
    },
    loadData: async function() {
      if (!this.appointmentId) {
        this.isLoading = false;
        return;
      }

      console.log("getting appointment");
      this.isLoading = true;
      try {
        // load appointment
        const appointmentSnap = await db
          .collection(C.COLLECTION.APPOINTMENTS)
          .doc(this.appointmentId)
          .get();
        this.appointment = appointmentSnap.data();
        this.appointment.id = this.appointmentId;
        console.log("got appointment");
        console.log(this.appointment);
        // load service
        const serviceSnap = await db
          .collection(C.COLLECTION.SERVICE_CATALOG)
          .doc(this.appointment.serviceId)
          .get();
        this.service = serviceSnap.data();
        this.service.id = this.appointment.serviceId;
        console.log("got service");
        // load watch
        const watchSnap = await db
          .collection(C.COLLECTION.WATCHES)
          .doc(this.appointment.watchId)
          .get();
        this.watch = watchSnap.data();
        this.watch.id = this.appointment.watchId;
        console.log("got watch");
        // load watchModel
        const watchModelSnap = await db
          .collection(C.COLLECTION.WATCH_CATALOG)
          .doc(this.watch.modelId)
          .get();
        this.watchModel = watchModelSnap.data();
        this.watchModel.id = this.watch.modelId;
        console.log("got watchModel");
      } catch (error) {
        console.log("Error laoding appointment data: ", error);
      }
      this.isLoading = false;
    },
    allServices: function() {
      this.isLoading = true;
      db.collection(C.COLLECTION.SERVICE_CATALOG)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const docdata = doc.data();
            docdata.id = doc.id;
            this.servicesList.set(doc.id, docdata);
          });

          console.log("REACHED ALLSERVICES");

          if (this.asyncFlags[0]) this.loadingDone();
          else this.asyncFlags[1] = true;
        })
        .catch(error => {
          this.loadingDone(error);
          console.log("Error getting services: ", error);
        });
    },
    showChangeDateDialog(index) {
      this.validationMsg = "";
      // load data
      this.appointmentId = this.appointmentsList[index].id;
      this.editData.date = this.$C.getDateFromTimestamp(this.appointmentsList[index].date);
      this.editData.name = this.servicesList.get(this.appointmentsList[index].serviceId).name;
      // open dialog
      this.$refs["edit-appointment-modal"].show();
    },
    updateAppointment() {
      this.isSaving = true;
      db.collection(C.COLLECTION.APPOINTMENTS)
        .doc(this.appointmentId)
        .set(
          {
            modifiedOn: serverTimestamp,
            date: this.editData.date,
            status: 0
          },
          { merge: true }
        )
        .then(() => {
          this.$refs["edit-appointment-modal"].hide();
          this.isSaving = false;
          toast.success("Appointment date changed.");
        })
        .catch(error => {
          this.isSaving = false;
          toast.error("Couldn't change appointment date. " + error.message);
        });
    },
    cancelAppointment(index) {
      const appointment = this.appointmentsList[index];
      if (appointment.status == 0) {
        console.log("delete appointment...");
        if (confirm("Do you really want to cancel this appointment?")) {
          var docRef = db.collection(C.COLLECTION.APPOINTMENTS).doc(appointment.id);
          docRef
            .delete()
            .then(() => {
              console.log("Appointment successfully canceled!");
            })
            .catch(error => {
              console.error("Error cancelling appointment: ", error);
            });
        }
      } else {
        console.log("send appointment cancelation request...");
        db.collection(C.COLLECTION.APPOINTMENTS)
          .doc(this.appointmentId)
          .set(
            {
              modifiedOn: serverTimestamp,
              cancelledOn: serverTimestamp,
              status: this.$C.STATUS.APPOINTMENT_STATUS.CANCELLATION_PENDING
            },
            { merge: true }
          )
          .then(() => {
            toast.success("Appointment cancellation requested.");
          })
          .catch(error => {
            toast.error("Error cancelling appointment: " + error.message);
          });
      }
    }
  },
  created() {
    console.log("created");
    //get watch id from params
    if (this.$route.params.appointmentId != null && this.$route.params.appointmentId != "") {
      this.appointmentId = this.$route.params.appointmentId;
    }
    console.log("appointmentID: " + this.appointmentId);
    this.loadData();
  }
  // mounted() {
  // },
};
</script>

<style scoped></style>
